import styled from 'styled-components';
import BackgroundImage from '../../images/milky_way.jpg';

export const StyledContactSection = styled.section`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  display: flex;

  &::before {
    background: ${(props) => props.theme.colors.primary};
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 5rem;
`;


