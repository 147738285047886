import styled from 'styled-components';

export const StyledResumeSection = styled.section`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  border-radius: 0.5rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
`;

export const Container = styled.div`
  margin: 2rem;

`;

export const ResumeEntry = styled.div`
  margin-bottom: 2rem;
  border-left: 3px solid ${props => props.theme.colors.secondary};
  padding-left: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const EntryHeader = styled.h2`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    width: fit-content;
  }
`;

export const Company = styled.h3`
  color: ${props => props.theme.colors.highlight};
`;

export const BulletPoints = styled.ul`
  margin-bottom: 1rem;

  li {
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
`;
