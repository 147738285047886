import styled from 'styled-components';

export const StyledSkillsSection = styled.section`
  margin: 2rem;
  background: ${props => props.theme.colors.primary};


`;

export const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

export const SkillCard = styled.div`
  border: 2px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.secondary};
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
    border-color: ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.accent};
  }
`;

export const SkillName = styled.h4`
  position: relative;
  z-index: 1;
`;
