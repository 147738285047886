import React from 'react';
import {IntroText, StyledOrbitEntry} from './styles';
import {Parallax} from 'react-scroll-parallax';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLaptopCode} from '@fortawesome/free-solid-svg-icons';

const OrbitEntry = () => (
    <Parallax speed={10}>
        <StyledOrbitEntry id={"orbitEntry"}>
            <IntroText>
                <FontAwesomeIcon icon={faLaptopCode} size="4x"/>
                <h1>Hello, I'm Harmony</h1>
                <h4>Ready to explore the digital universe.</h4>
                <p>Software Engineer | Front-end Developer | Cyberspace Navigator</p>
            </IntroText>
        </StyledOrbitEntry>
    </Parallax>
);

export default OrbitEntry;
