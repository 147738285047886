import styled from 'styled-components';
import BackgroundImage from '../../images/milky_way.jpg';

export const StyledOrbitEntry = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: ${props => props.theme.colors.tertiary};
  background: url(${BackgroundImage}) no-repeat center center fixed;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.colors.primary};
    opacity: 0.8;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

`;
export const IntroText = styled.div`
  max-width: 500px;
`;



