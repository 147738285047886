import React from 'react';
import {StyledTrainingCentersSection, SubSection, Timeline, TimelineEntry} from './styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {certificationDetails, educationDetails} from "./background";

interface IDetails {
    title: string;
    details: {
        title: string;
        subtitle: string;
        description: string
    }[];
}

const TrainingCentersSection = () => {
    const DetailsSection = ({title, details}: IDetails) => (
        <SubSection>
            <h3>{title}</h3>
            <Timeline>
                {details.map(entry => (
                    <TimelineEntry key={entry.title}>
                        <h4>{entry.title}</h4>
                        <h5>{entry.subtitle}</h5>
                        <p>{entry.description}</p>
                    </TimelineEntry>
                ))}
            </Timeline>
        </SubSection>
    );

    return (
        <StyledTrainingCentersSection>
            <h2><FontAwesomeIcon icon={faBook}/> Training Centers</h2>
            <DetailsSection title="Education" details={educationDetails}/>
            <DetailsSection title="Certifications" details={certificationDetails}/>
        </StyledTrainingCentersSection>
    );
};

export default TrainingCentersSection;
