import React from 'react';
import {SkillCard, SkillName, SkillsGrid, StyledSkillsSection} from './styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandSpock} from "@fortawesome/free-solid-svg-icons";

const skills: string[] = [
    "TypeScript",
    "React",
    "JavaScript",
    "HTML5",
    "CSS",
    "Redux",
    "Jest",
    "Freemarker",
    "Git",
    "Sentry",
    "Styled-Components",
    "AntD"
];

const SkillsSection = () => (
    <StyledSkillsSection>
        <h2><FontAwesomeIcon icon={faHandSpock}/>Crew Skills</h2>
        <SkillsGrid>
            {skills.map(skill => (
                <SkillCard key={skill}>
                    <SkillName>{skill}</SkillName>
                </SkillCard>
            ))}
        </SkillsGrid>
    </StyledSkillsSection>
);

export default SkillsSection;
