import {BrowserRouter as Router} from "react-router-dom";
import {Element} from 'react-scroll';
import {GlobalStyles} from './GlobalStyles';
import theme from './theme';
import {ThemeProvider} from 'styled-components';
import Navbar from './components/Navbar';
import OrbitEntry from './components/OrbitEntry';
import ProjectsSection from './components/ProjectsSection';
import SkillsSection from './components/SkillsSection';
import ResumeSection from './components/ExperienceSection';
import ContactSection from './components/ContactSection';
import {Contents, Layout} from "./components/Navbar/styles";
import EducationAndCertificationsSection from "./components/EducationAndCertificationsSection";
import {ParallaxProvider} from 'react-scroll-parallax';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <GlobalStyles/>
                <ParallaxProvider>
                    <Layout>
                        <Navbar/>
                        <Contents><Element name="orbitEntry"><OrbitEntry/></Element>
                            <Element name="skills"><SkillsSection/></Element>
                            {/*<Element name="projects"><ProjectsSection/></Element>*/}
                            <Element name="resume"><ResumeSection/></Element>
                            <Element name="educationAndCertifications"><EducationAndCertificationsSection/></Element>
                            <Element name="contact"><ContactSection/></Element></Contents>
                    </Layout>
                </ParallaxProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
