export const educationDetails = [
    {
        title: 'Western Governors University, Online — Software Development, B.S.',
        subtitle: 'February 2021 - February 2023',
        description: 'Classes included the following topics: Java, SQL, HTML5, CSS, JavaScript, networks, quality assurance testing, wireframing, project management, and organizational leadership.'
    }, {
        title: 'V School, Salt Lake City, UT — Bootcamp',
        subtitle: 'January 2019 - April 2019',
        description: 'Made several different applications that involved React, JavaScript, CSS, HTML, Node, Express, MongoDB, and using git'
    },
    {
        title: 'Northwest College, Powell, WY — General Studies, A.S.',
        subtitle: 'August 2016 - May 2018',
        description: 'Classes included the following topics: Python, C++, and Assembly Language (three semesters of assembly too many)'
    },];
export const certificationDetails = [
    {
        title: 'CompTIA Project+ Certification',
        subtitle: 'July 2021',
        description: 'This covers project management concepts such as communication skills, team building, managing resources, the project life cycle, and project documentation.'
    },
    {
        title: 'Certified Internet Webmaster User Interface Designer (CIW)',
        subtitle: 'June 2021',
        description: 'This includes the following topics: wireframing, user-focused design, and developing websites with accessibility in mind'
    },
    {
        title: 'CompTIA A+,',
        subtitle: 'August 2021',
        description: 'The CompTIA A+ was a lot of info on networks and hardware and how to troubleshoot'
    },
];