import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${(props) => props.theme.fonts.body};
    color: ${(props) => props.theme.colors.tertiary};
    background-color: ${(props) => props.theme.colors.primary};
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fonts.heading};
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
    color: ${props => props.theme.colors.tertiary};
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.75rem;
    color: ${props => props.theme.colors.tertiary};
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.colors.text};
  }

  h5 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.colors.text};

  }

  a {
    color: ${props => props.theme.colors.text};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.tertiary};
    }
  }

  p {
    font-size: 1rem;
    color: ${props => props.theme.colors.text};
  }
`;
