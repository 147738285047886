import styled from 'styled-components';

export const StyledTrainingCentersSection = styled.section`
  margin: 2rem;
  background: ${props => props.theme.colors.primary};
`;

export const SubSection = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimelineEntry = styled.div`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;


  &:last-child {
    margin-bottom: 0;
  }
`;
