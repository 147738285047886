import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;
export const StyledNavbar = styled.nav<{ open: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  padding: 1rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.tertiary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  width: 100%;


  @media (max-width: 768px) {
    padding: ${(props) => (props.open ? '1rem' : '0')};
    height: ${(props) => (props.open ? '100vh' : 'fit-content')};
    position: ${(props) => (props.open ? 'fixed' : 'sticky')};
  }
`;


export const NavList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  text-align: left;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;


export const NavItem = styled.li`
  margin-bottom: 2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  align-items: center;

  a {
    display: flex;
    gap: 1rem;
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 10;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;


export const Overlay = styled.div<{ isOpen: boolean }>`
  position: ${(props) => (props.isOpen ? 'fixed' : 'sticky')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
`;