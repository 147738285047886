import React from 'react';
import {SocialLinks, StyledContactSection} from './styles';
import {Parallax} from 'react-scroll-parallax';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSatellite} from "@fortawesome/free-solid-svg-icons";

const socialLinks = [
    {name: 'LinkedIn', url: 'https://www.linkedin.com/in/hkunzler/'},
    {name: 'GitHub', url: 'https://github.com/hkunzler'},
];

const ContactSection = () => (
    <Parallax speed={10}>
        <StyledContactSection>
            <h2><FontAwesomeIcon icon={faSatellite}/> Communication Frequencies</h2>
            <p>Feel free to reach out to me via email at harmony@kunzler.org or on any of the platforms below:</p>
            <SocialLinks>
                {socialLinks.map(link => (
                    <a key={link.name} href={link.url} target="_blank"
                       rel="noopener noreferrer">{link.name}</a>
                ))}
            </SocialLinks>
        </StyledContactSection></Parallax>
);

export default ContactSection;
