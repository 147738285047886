import React from 'react';
import {BulletPoints, Company, Container, EntryHeader, ResumeEntry, StyledResumeSection} from './styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScroll} from "@fortawesome/free-solid-svg-icons";
import {carGurusRoleDetails, learnInRoleDetails} from "./roles";

interface IRoleEntry {
    role: string,
    bulletPoints: string[],
}

const RoleEntry = ({role, bulletPoints}: IRoleEntry) => (
    <>
        <h5>{role}</h5>
        <BulletPoints>
            {bulletPoints.map((bullet, index) => (
                <li key={index}>{bullet}</li>
            ))}
        </BulletPoints>
    </>
);

interface IResumeEntry {
    company: string,
    roles: IRoleEntry[],
}

const ResumeSection = () => {

    const ResumeJobEntry = ({company, roles}: IResumeEntry) => (
        <ResumeEntry>
            <Company>{company}</Company>
            {roles.map((roleDetail, index) => (
                <RoleEntry key={index} role={roleDetail.role} bulletPoints={roleDetail.bulletPoints}/>
            ))}
        </ResumeEntry>
    );

    return (
        <Container><EntryHeader><FontAwesomeIcon icon={faScroll}/> Mission Logs </EntryHeader>
            <StyledResumeSection id="experience">
                <ResumeJobEntry {...learnInRoleDetails} />
                <ResumeJobEntry {...carGurusRoleDetails} />
            </StyledResumeSection></Container>
    );
};

export default ResumeSection;
