export const theme = {
    colors: {
        primary: '#312244',  // Dark Cosmos
        secondary: '#F27A7A',  // Nebula Pink
        tertiary: '#F9D342',  // Star Dust
        accent: '#38A3A5',  // Teal Comet
        text: '#F6F6E9'  // Milky White
    },
    fonts: {
        main: '"Montserrat", sans-serif',  // Main font
        secondary: '"Open Sans", sans-serif'  // Secondary font
    },
    breakpoints: {
        mobile: 'only screen and (max-width: 50rem)',
        tablet: 'only screen and (max-width: 65rem)'
    }
};

export default theme;
