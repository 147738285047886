import {faBook, faHandSpock, faRocket, faSatellite, faScroll, faUserAstronaut} from "@fortawesome/free-solid-svg-icons";

export const navItems = [
    {
        id: "orbitEntry",
        icon: faUserAstronaut,
        text: "Cybernaut's Launchpad",
    },
    {
        id: "skills",
        icon: faHandSpock,
        text: "Crew Skills",
    },
    // {
    //     id: "projects",
    //     icon: faRocket,
    //     text: "Launch Projects",
    // },
    {
        id: "resume",
        icon: faScroll,
        text: "Mission Logs",
    },
    {
        id: "educationAndCertifications",
        icon: faBook,
        text: "Training Centers",
    },
    {
        id: "contact",
        icon: faSatellite,
        text: "Communication Frequencies",
    },
];