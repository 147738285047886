export const learnInRoleDetails = {
    company: "Learn In, Remote",
    roles: [
        {
            role: "Software Engineer, April 2022 - Present",
            bulletPoints: [
                "Developed a branding feature that consisted of color fields and preview pages to test color themes...",
                "Built a modal using TypeScript for adding events, including date, time (with time zones)...",
                "Created a page that allows users to set their content as private or public...",
                "Contributed to a team effort to significantly enhance website accessibility...",
            ],
        },
    ],
};
export const carGurusRoleDetails = {
    company: "CarGurus, Cambridge, MA",
    roles: [
        {
            role: "Software Engineer, Digital Retail, May 2020 - April 2022",
            bulletPoints: [
                "Built a feature that allowed shoppers to send their prequalified loan rates via text...",
                "Principal Contributor on a small team to build a feature that gave shoppers access to an educational page...",
                "Added a way to use a logged-out shoppers email to send a finance retargeting email...",
                "Contributed to the new CarGurus checkout experience by building a feature that allowed shoppers to schedule an appointment...",
                "Added an educational video to an existing page that contributed to a 5% increase in mobile shoppers...",
            ],
        },
        {
            role: "Associate Software Engineer, Infrastructure, August 2019 - May 2020",
            bulletPoints: [
                "Added an accessibility feature that provides shoppers the ability to skip to the main content of the page...",
                "Audited the global assets in the site and identified dead or duplicated assets to remove...",
                "Key contributor in migrating from a legacy sitewide shell to a modern version...",
                "Built the interface that broke the internal data into multiple pages...",
            ],
        },
    ],
};