import React, {useState} from 'react';
import {Link as ScrollLink} from 'react-scroll/modules';
import {MobileIcon, NavItem, NavList, Overlay, StyledNavbar} from './styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons'
import {navItems} from "./navItems";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
        if (e.target instanceof HTMLElement && e.target.id === "overlay" && isOpen) setIsOpen(false);
    };

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <Overlay onClick={handleClickOutside} isOpen={isOpen}>
            <MobileIcon onClick={toggleMenu}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars}/>
            </MobileIcon>
            <StyledNavbar open={isOpen}>
                <NavList isOpen={isOpen}>
                    <h3>Harmony Kunzler</h3>
                    {navItems.map(({id, icon, text}) => (
                        <NavItem key={id}>
                            <ScrollLink
                                onClick={() => setIsOpen(false)}
                                to={id}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <FontAwesomeIcon size="2x" icon={icon}/> {text}
                            </ScrollLink>
                        </NavItem>
                    ))}
                </NavList>
            </StyledNavbar>
        </Overlay>
    );
};

export default Navbar;
